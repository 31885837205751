import { listFilesInS3Folder, uploadFileToS3 } from './base';

export const getFiles = async (user, resolver) => {
  try {
    // Define the folder path based on the user
    const folderPath = `DashboardUsers/${user}/`;

    // Call listFilesInS3Folder to get the list of files
    const fileKeys = await listFilesInS3Folder(folderPath);

    // Map file keys to objects with file names
    const fileObjects = fileKeys.map((key) => ({ fileName: key }));

    // Call the resolver function with the list of file objects
    resolver(fileObjects);
  } catch (e) {
    console.error('Error:', e);
  }
};

export const uploadFile = async (file, user, resolver) => {
  try {
    // Define the folder path based on the user
    const folderPath = `DashboardUsers/${user}`;

    // Call uploadFileToS3 to upload the file
    const fileKey = await uploadFileToS3(file, folderPath);

    // Call the resolver function with the file key or other relevant information
    resolver(fileKey);
  } catch (e) {
    console.error('Error uploading file:', e);
  }
};
