import React, { useState, useEffect } from 'react';
import { create } from 'zustand';
import { getFiles, uploadFile } from 'src/queries/files';

export const useFileUpload = create((set, get) => {
  return {
    files: [],
    loadingFiles: true,
    uploadLoading: false,
    showSuccess: false,
    closeSuccess: () => {
      set({ showSuccess: false });
    },
    getFiles: (username) => {
      getFiles(username, (files) => {
        set({
          files,
          loadingFiles: false,
        });
      });
    },
    uploadFile: (file, username) => {
      set({
        uploadLoading: true,
      });
      const { files } = get();

      uploadFile(file, username, (fileName) => {
        let filesCopy = [...files];
        filesCopy.push(fileName);
        set({
          uploadLoading: false,
          files: filesCopy,
          showSuccess: true,
        });
      });
    },
  };
});
