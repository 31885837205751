import { API, graphqlOperation } from 'aws-amplify';
import { apiQuery, apiBatchQuery } from './base';
import { listMedicationLogs, listWellnessLogs } from '../graphql/queries';

const mapLogs = (wellnessLogs, medicationLogs, appUsers, resolver) => {
  const logs = wellnessLogs
    .filter((log) => {
      const { appUserID } = log;
      return appUsers.some((user) => user.id === appUserID);
    })
    .map((log) => {
      const { appUserID, id, owner, ...logNoID } = log;
      const appUser = appUsers.find((user) => user.id === appUserID);
      const { username } = appUser;

      const medicationLog = medicationLogs.find((medLog) => medLog.id === log.id);
      const fullLog = {
        username: username,
        medications: medicationLog?.medications || [],
        ...logNoID,
      };
      return fullLog;
    });
  resolver(logs);
};

function splitArray(inputArray, chunkSize) {
  const result = [];
  for (let i = 0; i < inputArray.length; i += chunkSize) {
    result.push(inputArray.slice(i, i + chunkSize));
  }
  return result;
}

export const getWellnessLogsForUsers = async (appUsers, setWellnessLogs) => {
  const appUserFilters = appUsers.map((user) => {
    return { appUserID: { eq: user.id } };
  });
  const filterSets = splitArray(appUserFilters, 20);
  let wellnessLogs = [];
  let medicationLogs = [];

  try {
    await apiBatchQuery(
      listWellnessLogs,
      {},
      // { filter: { or: filterSet } },
      'listWellnessLogs',
      (data) => {
        wellnessLogs = wellnessLogs.concat(data);
      }
    );
    await apiBatchQuery(
      listMedicationLogs,
      {},
      // { filter: { or: filterSet } },
      'listMedicationLogs',
      (data) => {
        medicationLogs = medicationLogs.concat(data);
      }
    );
    mapLogs(wellnessLogs, medicationLogs, appUsers, setWellnessLogs);
  } catch (e) {
    console.log(e);
  }
};

export const getWellnessLogsForUser = (appUserID, setWellnessLogs) => {
  return apiQuery(listWellnessLogs, { filter: { appUserID: { eq: appUserID } } }, setWellnessLogs);
};
