import React, { useEffect, useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Stack, Snackbar } from '@mui/joy';
import { Typography } from '@mui/material';
import { useAuth, useFileUpload } from 'src/hooks';
import { Header, Card, StyledDropzone, Button, LoadingSpinner } from 'src/components';
import { useDropzone } from 'react-dropzone';

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.offWhite.main,
  height: '100dvh',
  display: 'flex',
}));

const LoadingContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 100px)',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: 3,
}));

const Loading = () => {
  return (
    <LoadingContainer>
      <LoadingSpinner sx={{ height: '5vmin !important', width: '5vmin !important' }} />
      <Typography variant="h3" sx={{ color: '#333333' }}>
        Uploading File ...
      </Typography>
    </LoadingContainer>
  );
};

export const FileUpload = (props) => {
  const [success, setSuccess] = useState(false);
  const { user } = useAuth();
  const theme = useTheme();
  const { uploadLoading, uploadFile, showSuccess, closeSuccess } = useFileUpload();

  const [file, setFile] = useState(null);
  const onDrop = (files) => {
    setFile(files[0] || null);
  };

  const dropzoneProps = useDropzone({ onDrop });
  const { acceptedFiles, getRootProps, getInputProps } = dropzoneProps;

  const onSubmit = () => {
    uploadFile(acceptedFiles[0], user.username);
    setFile(null);
  };

  useEffect(() => {
    if (showSuccess) {
      setSuccess(true);
      closeSuccess();
    }
  }, [showSuccess]);

  return (
    <Container {...props}>
      <Header title="Upload Files" />
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          height: 'calc(100% - 70px)',
        }}>
        {/* <Card
          sx={{ marginTop: '20px', width: '45%', height: '100%' }}
          title={'Previously Uploaded Files'}></Card> */}
        <Card sx={{ marginTop: '20px', width: '100%', height: '100%' }} title={'Upload Files'}>
          {uploadLoading ? (
            <Loading />
          ) : (
            <Stack sx={{ marginTop: '20px' }}>
              <StyledDropzone dropzoneProps={dropzoneProps} files={[file]} />
              <Button
                sx={{
                  height: '40px !important',
                  width: '150px',
                  fontFamily: 'Comfortaa, Now, Arial',
                  alignSelf: 'flex-end',
                }}
                onClick={onSubmit}
                disabled={!file}>
                Submit Files
              </Button>
            </Stack>
          )}
        </Card>
      </Stack>
      {/* <Snackbar autoHideDuration={10} color="success" open={success}>
        Your file was successfully submitted!
      </Snackbar> */}
    </Container>
  );
};
