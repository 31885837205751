import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { useDashStore, useExercise } from 'src/hooks';
import { Header, Select, Alert } from 'src/components';
import { ExerciseTable } from './ExerciseTable';

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.offWhite.main,
  height: '100dvh',
  display: 'flex',
}));

export const Exercise = (props) => {
  const { selectedUser, setSelectedUser, userOptions } = useDashStore();

  const { mostRecentLogs, getExerciseSessionsForUsers, setLoading } = useExercise();

  useEffect(() => {
    if (userOptions.length > 1) {
      let options = userOptions.slice(1, userOptions.length);
      getExerciseSessionsForUsers(options);
    } else {
      setLoading(false);
    }
  }, [userOptions]);

  const setNewUser = (val) => {
    const newUserObject = userOptions.find((i) => i.username === val);
    setSelectedUser(newUserObject);
  };

  return (
    <Container {...props}>
      <Header title="Exercise Logs">
        <Select
          id="user-select"
          options={userOptions.map((i) => i.username)}
          value={selectedUser.username}
          setValue={setNewUser}
          sx={{ minWidth: '200px' }}
        />
      </Header>
      <ExerciseTable />
    </Container>
  );
};
