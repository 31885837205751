import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { useDashStore, useBiometrics } from 'src/hooks';
import { Header, Select, Alert } from 'src/components';
import { BiometricsChart } from './BiometricsChart';
import { AllUsersBioTable } from './AllUsersBioTable';
import { spaceSeparated } from './helpers';

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.offWhite.main,
  height: '100dvh',
  display: 'flex',
}));

export const Biometrics = (props) => {
  const { selectedUser, setSelectedUser, userOptions, group, showAlert, dismissAlert } =
    useDashStore();
  const allUsers = selectedUser.id === 'All Users';

  const { getBiometrics, biometrics } = useBiometrics();

  useEffect(() => {
    getBiometrics(group.name, userOptions.slice(1, userOptions.length));
  }, [group, userOptions]);

  const setNewUser = (val) => {
    const newUserObject = userOptions.find((i) => i.username === val);
    setSelectedUser(newUserObject);
  };

  const biometricOptions = biometrics.map((option) => {
    if (option === 'SleepLevel') {
      return 'Sleep';
    }
    return option;
  });

  return (
    <Container {...props}>
      <Header title="Biometrics">
        <Select
          id="biometric-select"
          options={biometricOptions}
          value={useBiometrics().selectedBio}
          setValue={useBiometrics().setSelectedBio}
          customRender={spaceSeparated}
          sx={{ minWidth: '200px' }}
        />
        <Select
          id="user-select"
          options={userOptions.map((i) => i.username)}
          value={selectedUser.username}
          setValue={setNewUser}
          sx={{ minWidth: '200px' }}
        />
      </Header>
      {allUsers ? <AllUsersBioTable /> : <BiometricsChart />}
    </Container>
  );
};
