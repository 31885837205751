import React, { useState, useEffect } from 'react';
import { create } from 'zustand';
import { createEducation, attachEducationToGroups } from 'src/queries/education';

let educationItems = [
  {
    title: 'Post Transplant Q&A',
    type: 'pdf',
    emoji: 'ℹ️', // UPDATE
    ref: 'KidneyTransplantQA.pdf', // UPDATE
    tags: ['General', 'Kidney', 'Transplant'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Pain Management',
    type: 'pdf',
    emoji: '❤️', // UPDATE
    ref: 'KidneyTransplantPain.pdf', // UPDATE
    tags: ['Pain', 'Kidney', 'Transplant'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Nutrition After Your Transplant',
    type: 'pdf',
    emoji: '🍽️', // UPDATE
    ref: 'KidneyTransplantNutrition.pdf', // UPDATE
    tags: ['Nutrition', 'Kidney', 'Transplant'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Kidney Transplant Diet',
    type: 'pdf',
    emoji: '🍴', // UPDATE
    ref: 'KidneyTransplantDiet.pdf', // UPDATE
    tags: ['Nutrition', 'Kidney', 'Transplant'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Kidney Transplant Medication',
    type: 'pdf',
    emoji: '💊', // UPDATE
    ref: 'KidneyTransplantMed.pdf', // UPDATE
    tags: ['Medicaiton', 'Kidney', 'Transplant'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    title: 'Infection Prevention',
    type: 'pdf',
    emoji: '🦠', // UPDATE
    ref: 'InfectionPrevention.pdf', // UPDATE
    tags: ['Kidney', 'Transplant'],
    isPublic: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const useEducation = create((set, get) => {
  return {
    createEducation: () => {
      educationItems.forEach((educationItem) => {
        createEducation(educationItem, []);
      });
      // return createEducation(education);
    },
    attachEducationToGroups: (educationID, groups) => {
      return attachEducationToGroups(educationID, groups);
    },
  };
});
