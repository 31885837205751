import { API, graphqlOperation } from 'aws-amplify';
import { apiQuery, apiBatchQuery } from './base';

const listExerciseSessions = /* GraphQL */ `
  query ListExerciseSessions(
    $filter: ModelExerciseSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExerciseSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateAndTimeSubmitted
        dateAndTimeToLog
        appUserID
        duration
        durationUnit
        exercises {
          id
          exerciseType
          exerciseName
          resistance
          numSets
          repsPerSet
          exertion
          distance
          distanceUnit
          duration
          durationUnit
          usedAssistanceDevice
        }
      }
      nextToken
      __typename
    }
  }
`;

const mapExercises = (logs, appUsers, resolver) => {
  const exercises = [];
  logs.forEach((log) => {
    const { appUserID, id, ...logNoID } = log;
    const appUser = appUsers.find((user) => user.id === appUserID);
    if (appUser) {
      const { username } = appUser;

      const fullLog = {
        username: username,
        ...logNoID,
      };
      exercises.push(fullLog);
    }
  });
  resolver(exercises);
};

export const getExerciseSessionsForUsers = async (appUsers, setExerciseSessions) => {
  const appUserFilters = appUsers.map((user) => {
    return { appUserID: { eq: user.id } };
  });
  let exerciseSessions = [];

  try {
    await apiBatchQuery(
      listExerciseSessions,
      { filter: { or: appUserFilters } },
      'listExerciseSessions',
      (data) => {
        exerciseSessions = data;
        mapExercises(exerciseSessions, appUsers, setExerciseSessions);
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export const getExerciseSessionsForUser = (appUserID, setExerciseSessions) => {
  return apiQuery(
    listExerciseSessions,
    { filter: { appUserID: { eq: appUserID } } },
    setExerciseSessions
  );
};
