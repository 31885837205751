/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/joy';
import { Typography } from '@mui/material';
import { Modal, Button } from 'src/components';
import { useAuth } from 'src/hooks';

const ModalContainer = styled(Stack)({
  flexDirection: 'column',
  textAlign: 'center',
  gap: 24,
  margin: '20px 0px',
});
const ButtonRow = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-around',
});
const StyledButton = styled(Button)({
  height: '40px !important',
  minWidth: '100px',
  width: '15vw',
});

const inactivityTime = 600; // 10 minutes

export const SessionTimeout = () => {
  const [countdown, setCountdown] = useState(inactivityTime);
  const [last30Seconds, setLast30Seconds] = useState(false);

  const { logout } = useAuth();

  const clear = () => {
    setLast30Seconds(false);
    setCountdown(inactivityTime);
  };

  const handleLogout = () => {
    clear();
    logout();
  };

  const handleContinue = () => {
    clear();
  };

  const onUserActivity = () => {
    if (!last30Seconds) {
      clear();
    }
  };

  // timeouts & intervals
  useEffect(() => {
    let timeoutID;

    if (countdown === 29) {
      setLast30Seconds(true);
    } else if (countdown === 0) {
      handleLogout();
      return;
    }

    timeoutID = setTimeout(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [countdown, last30Seconds]);

  // user activity
  useEffect(() => {
    const events = ['mousedown', 'keydown', 'mousemove'];

    events.forEach((event) => {
      document.addEventListener(event, onUserActivity);
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, onUserActivity);
      });
    };
  }, [last30Seconds]);

  return (
    <React.Fragment>
      <Modal open={last30Seconds} setOpen={() => {}} title="Still there?">
        <ModalContainer>
          <Typography variant="instructions" sx={{ maxWidth: '500px' }}>
            {`Due to inactivity, you will be automatically logged out in ${countdown} seconds. If
            you're still working, click Continue!`}
          </Typography>
          <ButtonRow>
            <StyledButton variant="secondary" onClick={handleContinue}>
              Continue
            </StyledButton>
            <StyledButton onClick={handleLogout}>Logout</StyledButton>
          </ButtonRow>
        </ModalContainer>
      </Modal>
    </React.Fragment>
  );
};
