/* eslint-disable no-undef */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Stack } from '@mui/joy';
import { LoadingSpinner } from 'src/components';

export const spaceSeparated = (input) => {
  return input.replace(/([A-Z])/g, ' $1').trim();
};

export const getBioKey = (bio) => {
  return bio === 'Sleep' ? 'sleepLevel' : bio.charAt(0).toLowerCase() + bio.slice(1);
};

/* Loading Spinner */
const LoadingContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 100px)',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: 3,
}));
export const Loading = ({ text }) => {
  return (
    <LoadingContainer>
      <LoadingSpinner sx={{ height: '5vmin !important', width: '5vmin !important' }} />
      <Typography variant="h3" sx={{ color: '#333333' }}>
        Loading {text}
      </Typography>
    </LoadingContainer>
  );
};

function renderDateString(row, id) {
  const date = new Date(row[id]);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const timeDiff = today.getTime() - date.getTime();
  const dayDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

  if (date.toDateString() === today.toDateString()) {
    return 'Today';
  } else if (date.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  } else if (dayDiff < 7) {
    return `${dayDiff} Days Ago`;
  } else if (date.getFullYear() === today.getFullYear()) {
    const options = { month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  } else {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }
}

export const bioDataPointsByUserColumns = [
  {
    headerName: 'ID',
    field: 'username',
    minWidth: 130,
    flex: 2,
  },
  {
    headerName: '# Data Points',
    field: 'numDataPoints',
    minWidth: 120,
    flex: 2,
  },
  {
    headerName: 'First Date Captured',
    field: 'firstDate',
    renderCell: (params) => {
      return renderDateString(params.row, 'firstDate');
    },
    minWidth: 150,
    flex: 3,
  },
  {
    headerName: 'Last Date Captured',
    field: 'lastDate',
    renderCell: (params) => {
      return renderDateString(params.row, 'lastDate');
    },
    minWidth: 150,
    flex: 3,
  },
];

export const getBioDataByUser = (data) => {
  const transformedData = [];
  const userMap = new Map();
  // Process each data item
  data?.forEach((item) => {
    const { username, startDate } = item;
    // Update user information or create a new entry
    if (userMap.has(username)) {
      const userData = userMap.get(username);
      userData.numDataPoints++;
      userData.lastDate = startDate > userData.lastDate ? startDate : userData.lastDate;
      userData.firstDate = !startDate
        ? userData.firstDate
        : startDate < userData.firstDate || !userData.firstDate
        ? startDate
        : userData.firstDate;
    } else {
      userMap.set(username, {
        username,
        numDataPoints: 1,
        firstDate: startDate,
        lastDate: startDate,
      });
    }
  });
  // Convert user map to array
  userMap.forEach((userData) => {
    transformedData.push(userData);
  });
  return transformedData;
};

export function downloadBiometricCSV(data, filename, userOptions) {
  let modifiedData = data.replaceAll('appUserID', 'username');
  userOptions.slice(1, userOptions.length).forEach((user) => {
    modifiedData = modifiedData.replaceAll(user.id, user.username);
  });

  const blob = new Blob([modifiedData], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
