import React, { useMemo } from 'react';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#FEA71C',
};

const acceptStyle = {
  borderColor: '#FEA71C',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const StyledDropzone = ({ dropzoneProps, files }) => {
  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    dropzoneProps;

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const acceptedFileItems = files?.map((file) =>
    file ? (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ) : null
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag and drop your file here, or click to select a file from your computer</p>
      </div>
      <aside>
        <h4>Uploaded File:</h4>
        <ul>{acceptedFileItems}</ul>
      </aside>
    </div>
  );
};
