import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import Papa from 'papaparse';

/**
 * adminQuery: standard query for AdminQueries api
 * @param {*} body object w/ data to send
 * @param {*} path api path to post to (ex. '/createUser')
 * @returns
 */
export const adminQuery = async (body, path) => {
  let apiName = 'AdminQueries';
  let myInit = {
    body: body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  return await API.post(apiName, path, myInit);
};

/**
 * insightsQuery: standard query for insights api
 * @param {*} body object w/ data to send
 * @param {*} path api path to post to (ex. '/runInsights')
 * @returns
 */
export const insightsQuery = async (body, path) => {
  let apiName = 'insights';
  console.log(Auth);
  let auth = (await Auth.currentSession()).getAccessToken().getJwtToken();
  console.log(auth);
  let myInit = {
    body: body,
  };
  return await API.post(apiName, path, myInit);
};

/**
 * apiQuery: standard query for AppSync GraphQL api
 * @param {*} query graphql query
 * @param {*} modifiers modifiers to query (filters, limits, etc.)
 * @param {*} resolver resolver function to process data
 */
export const apiQuery = async (query, modifiers, resolver) => {
  try {
    const response = await API.graphql({
      ...graphqlOperation(query, modifiers),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
    resolver(response.data);
  } catch (error) {
    console.log(error);
  }
};

/**
 * apiBatchQuery: query for AppSync GraphQL api w/ batching
 * @param {*} query graphql query
 * @param {*} modifiers modifiers to query (filters, limits, etc.)
 * @param {*} resolver resolver function to process data
 * @param {*} queryName name of query (necessary to grab nextToken)
 * @param {*} batchSize # items to fetch per batch (AppSync is 100)
 */
export const apiBatchQuery = async (query, modifiers, queryName, resolver, batchSize = 100) => {
  let nextToken = null;
  let allItems = [];
  let prevItems = [];

  try {
    do {
      const response = await API.graphql({
        ...graphqlOperation(query, { ...modifiers, limit: batchSize, nextToken }),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });

      const { items, nextToken: responseNextToken } = response.data[queryName];

      prevItems = items;
      allItems = allItems.concat(items);
      nextToken = responseNextToken;
    } while (nextToken && prevItems.length);
    resolver(allItems);
  } catch (error) {
    console.log(error);
  }
};

/**
 * apiQuery: standard create mutation for AppSync GraphQL api
 * @param {*} mutation graphql create mutation
 * @param {*} input input object
 */
export const apiCreate = async (mutation, input) => {
  try {
    await API.graphql({
      ...graphqlOperation(mutation, {
        input: input,
      }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * apiDelete: standard delete mutation for AppSync GraphQL api
 * @param {*} mutation graphql delete mutation
 * @param {*} id id of object
 */
export const apiDelete = async (mutation, id) => {
  try {
    await API.graphql({
      ...graphqlOperation(mutation, {
        input: {
          id,
        },
      }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * getFromStorage: standard query for items in S3 storage
 * @param {*} filePath file path
 * @param {*} type type of file (pdf, csv, etc.)
 */
export const getFromStorage = async (filePath, type, dataResolver) => {
  try {
    if (type === 'csv') {
      const file = await Storage.get(filePath, { download: true });
      const csvData = await file.Body.text();
      Papa.parse(csvData, {
        delimiter: ',',
        header: true,
        quoteChar: '"',
        complete: (results) => {
          const dataArray = results.data;
          dataResolver(dataArray, csvData);
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else if (type === 'json') {
      const url = await Storage.get(filePath);
      const jsonContent = await fetch(url);
      const jsonData = await jsonContent.json();

      dataResolver(jsonData);
    } else {
      const url = await Storage.get(filePath);
      dataResolver(url);
    }
  } catch (error) {
    console.error('Error fetching or parsing S3 file:', error);
  }
};

/**
 * getFromStorage: standard query to list items in S3 storage at filePath
 * @param {*} filePath file path
 */
export const listFilesInS3Folder = async (filePath) => {
  try {
    // Use Storage.list to get objects in the folder
    const result = await Storage.list(filePath, { level: 'public' });

    // Extract file keys
    const files = result.map((item) => item.key);

    return files;
  } catch (error) {
    console.error('Error listing files in S3 folder:', error);
    throw error;
  }
};

/**
 * Uploads a file to S3 at a specified folder path.
 * @param {File | Blob} file - The file or Blob to be uploaded.
 * @param {string} folderPath - The folder path in S3 where the file will be uploaded.
 * @param {string} [accessLevel='public'] - The access level for the uploaded file ('public', 'protected', 'private').
 * @returns {Promise<string>} - The file key (path) of the uploaded file.
 */
export const uploadFileToS3 = async (file, folderPath, accessLevel = 'public') => {
  try {
    // Generate a unique file name (optional)
    const fileName = `${folderPath}/${file.name}`;

    // Upload the file to S3
    const result = await Storage.put(fileName, file, {
      level: accessLevel,
      contentType: file.type,
    });

    // Return the file key (path)
    return result.key;
  } catch (error) {
    console.error('Error uploading file to S3:', error);
    throw error;
  }
};
