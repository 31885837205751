import { API, Auth, graphqlOperation } from 'aws-amplify';
import { getFromStorage } from './base';

// const fetchFoods = async (foods) => {
//   try {
//     const apiKey = 'C3AgDcqTpCmuKgwhEkjgzqeYepzzvsCrFfwIjcAa'; // Replace with your USDA API key
//     const allFoodsResponses = [];

//     // Divide foods into batches of 20
//     const batches = [];
//     for (let i = 0; i < foods.length; i += 20) {
//       batches.push(foods.slice(i, i + 20));
//     }

//     for (const batch of batches) {
//       const fdcIds = batch.map((food) => food.fdcid).join(',');

//       // Build the USDA API URL
//       const url = `https://api.nal.usda.gov/fdc/v1/foods?fdcIds=${fdcIds}&api_key=${apiKey}&format=full`;

//       // Send the batch request
//       const response = await axios.get(url);

//       if (response.status === 200) {
//         // Format the data as required
//         const formattedFoods = response.data.map((fdcFood) => {
//           const correspondingFood = batch.find((food) => food.fdcid === fdcFood.fdcId);
//           return {
//             name: correspondingFood.name,
//             portion: correspondingFood.portion,
//             count: correspondingFood.count,
//             fdcid: correspondingFood.fdcid,
//             calories: getTotalCals(fdcFood, correspondingFood),
//           };
//         });

//         allFoodsResponses.push(...formattedFoods);
//       } else {
//         console.error(`Failed to fetch data for batch: Status Code ${response.status}`);
//       }
//     }

//     console.log(allFoodsResponses);
//     return allFoodsResponses;
//   } catch (error) {
//     console.error(`Error fetching data from USDA API: ${error.message}`);
//     throw error;
//   }
// };

const mapMeals = (data, appUsers, resolver) => {
  const meals = [];
  data.forEach((log) => {
    const { appUserID, id, ...logNoID } = log;
    const appUser = appUsers.find((user) => user.id === appUserID);
    if (appUser) {
      const { username } = appUser;
      const fullLog = {
        username: username,
        ...logNoID,
      };
      meals.push(fullLog);
    }
  });
  resolver(meals);
};

export const getNutritionData = async (group, appUsers, resolver) => {
  await getFromStorage(`${group}/nutrition.csv`, 'csv', (data) => {
    mapMeals(data, appUsers, resolver);
  });
};

// function splitArray(inputArray, chunkSize) {
//   const result = [];
//   for (let i = 0; i < inputArray.length; i += chunkSize) {
//     result.push(inputArray.slice(i, i + chunkSize));
//   }
//   return result;
// }

// export const getNutritionLogsForUsers = async (appUsers, setNutritionLogs) => {
//   const appUserFilters = splitArray(
//     appUsers.map((user) => {
//       return { appUserID: { eq: user.id } };
//     }),
//     50
//   );
//   let nutritionLogs = [];
//   let foods = [];

//   // grab logs
//   try {
//     await Promise.all(
//       appUserFilters.map(async (filters) => {
//         await apiBatchQuery(listMeals, { filter: { or: filters } }, 'listMeals', (data) => {
//           data.forEach((log) => {
//             nutritionLogs.push(log);
//             log.foods?.forEach((food) => {
//               foods.push(food);
//             });
//           });
//         });
//       })
//     );
//     if (foods.length) {
//       // fetch from FDC API
//       fetchFoods(foods)
//         .then((foodsData) => {
//           mapMeals(nutritionLogs, foodsData, appUsers, setNutritionLogs);
//         })
//         .catch((error) => console.error(`Error: ${error.message}`));
//     }
//   } catch (e) {
//     console.log(e);
//   }
// };

// export const getNutritionLogsForUser = (appUserID, setNutritionLogs) => {
//   return apiQuery(listMeals, { filter: { appUserID: { eq: appUserID } } }, setNutritionLogs);
// };
