import { apiCreate } from './base';
import { createEducation as create, updateGroup } from 'src/graphql/mutations';
import { v4 as uuidv4 } from 'uuid';

export const createEducation = (inputs, groups) => {
  let id = uuidv4();
  console.log(id);
  apiCreate(create, {
    id: id,
    ...inputs,
  });
  if (groups && groups.length) {
    attachEducationToGroups(id, groups);
  }
};

export const attachEducationToGroups = (educationID, groups) => {
  groups.forEach((group) => {
    let groupCopy = { ...group };
    let educationCopy = groupCopy.education;
    if (!educationCopy.includes(educationID)) {
      educationCopy.push(educationID);
      groupCopy.education = educationCopy;
      apiCreate(updateGroup, groupCopy);
    }
  });
};
