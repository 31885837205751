import React, { useState, useEffect, memo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  ResponsiveContainer,
  Brush,
  Bar,
  BarChart,
} from 'recharts';
import { Select, Header, IconButton } from 'src/components';
import { Loading } from '../../helpers';
import {
  getFirstDateInData,
  getLastDateInData,
  getDaysBetweenDates,
  ChartContainer,
  NoData,
  TooltipContainer,
  dateTickFormat,
} from './chartHelpers';

const levelColors = {
  wake: 'orange',
  rem: 'violet',
  light: 'lightblue',
  deep: 'darkblue',
};

const order = ['wake', 'light', 'deep', 'rem'];

const filterAndSortData = (data, device) => {
  const filteredData = data.filter((i) => i.deviceProductType.includes(device));
  console.log(device);
  if (device === 'Fitbit') {
    const newData = filteredData.map((i) => {
      return {
        date: new Date(i.startDate).getTime(),
        level: i.level,
        seconds: i.seconds,
      };
    });

    return newData.sort((a, b) => {
      return a.date - b.date;
    });
  } else {
    const newData = filteredData.map((i) => {
      return {
        id: i.id,
        activity: i.activity,
      };
    });

    return newData.sort((a, b) => {
      return a.id - b.id;
    });
  }
};

const FitbitTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const date = new Date(label);
    const dateLabel = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${String(
      date.getHours()
    ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    return (
      <TooltipContainer>
        <Typography variant="instructions">{dateLabel}</Typography>
        <Typography variant="paragraph">{`Level: ${
          payload[0].value !== 'wake' ? payload[0].value + ' sleep' : 'awake'
        }`}</Typography>
        <Typography variant="paragraph">{`Lasted ${payload[2].value} seconds`}</Typography>
      </TooltipContainer>
    );
  }
  return null;
};

const ActigraphTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const date = new Date(label);
    const dateLabel = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${String(
      date.getHours()
    ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    return (
      <TooltipContainer>
        <Typography variant="instructions">{label}</Typography>
        <Typography variant="paragraph">{`Activity: ${payload[0].value}`}</Typography>
      </TooltipContainer>
    );
  }
  return null;
};

const getExtendedDomain = (data, padding = 0.1) => {
  const dates = data.map((item) => new Date(item.date).getTime());
  const minDate = Math.min(...dates);
  const maxDate = Math.max(...dates);
  const range = maxDate - minDate;
  return [minDate - range * padding, maxDate + range * padding];
};

const FitbitChart = ({ data }) => {
  const theme = useTheme();
  const domain = getExtendedDomain(data, 0.1);

  return (
    <ResponsiveContainer width="95%" height="90%">
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          domain={['auto', 'auto']}
          type="number"
          scale="time"
          tickFormatter={dateTickFormat}
          tickMargin={8}
        />
        <YAxis
          type="category"
          dataKey="level"
          label={{ value: 'Sleep Level', angle: -45, position: 'insideLeft' }}
          categories={['wake', 'light', 'deep', 'rem']} // Order categories here
          reversed={true} // Reverses the order so 'wake' is at the top
        />
        <Tooltip content={FitbitTooltip} />
        <Brush
          dataKey="date"
          height={30}
          stroke={theme.palette.green.main}
          tickFormatter={dateTickFormat}
        />
        <Line
          type="monotone"
          dataKey="level"
          stroke={theme.palette.green.main}
          strokeWidth={3}
          dot={false}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey="level"
          stroke="none"
          strokeWidth={5}
          dot={(entry) => (
            <circle
              cx={entry.cx}
              cy={entry.cy}
              r={4}
              stroke={levelColors[entry.payload.level]}
              strokeWidth={5}
              fill={levelColors[entry.payload.level]}
            />
          )}
          activeDot={(entry) => (
            <circle
              cx={entry.cx}
              cy={entry.cy}
              r={5}
              stroke={levelColors[entry.payload.level]}
              strokeWidth={5}
              fill={levelColors[entry.payload.level]}
            />
          )}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey="seconds"
          stroke="none"
          strokeWidth={2}
          dot={false}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const ActigraphChart = ({ data }) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer width="95%" height="90%">
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="id" domain={['auto', 'auto']} type="number" tickMargin={8} />
        <YAxis dataKey="activity" type="number" label={'Activity'} angle={-45} domain={[0, 500]} />
        <Tooltip content={ActigraphTooltip} cursor={{ fill: theme.palette.offWhite.main }} />
        {/* <div style={{ width: '90%' }}> */}
        <Brush
          dataKey="id"
          height={30}
          stroke={theme.palette.green.main}
          // tickFormatter={dateTickFormat}
        />
        {/* </div> */}
        <Line
          type="monotone"
          dataKey="activity"
          stroke={theme.palette.green.main}
          strokeWidth={2}
          dot={{ stroke: theme.palette.green.main, strokeWidth: 5, r: 2 }}
          activeDot={{ stroke: theme.palette.green.main, strokeWidth: 5, r: 4 }}
          name="Activity"
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const SleepChart = ({ data }) => {
  const theme = useTheme();

  const uniqueDevices = [...new Set(data.map((item) => item.deviceProductType))];

  const defaultData = filterAndSortData(data, 'Fitbit');

  const [groupedData, setGroupedData] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  const [device, setDevice] = useState('Fitbit');

  useEffect(() => {
    const newData = filterAndSortData(data, device);
    setGroupedData(newData);
  }, [data]);

  useEffect(() => {
    const newData = filterAndSortData(data, device);
    setGroupedData(newData);
    setLoading(false);
  }, [device]);

  return (
    <ChartContainer>
      <Header title="Sleep" level={3} style={{ marginBottom: '20px' }}>
        <Select
          id="device-type-select"
          label="Device Type"
          options={uniqueDevices}
          value={device}
          setValue={(val) => {
            setLoading(true);
            setTimeout(() => {
              setDevice(val);
            }, 500);
          }}
          sx={{ minWidth: '200px', maxHeight: '40px' }}
          stackSX={{ marginTop: '-10px' }}
        />
      </Header>
      {loading ? (
        <Loading text={'Chart'} />
      ) : groupedData.length ? (
        device === 'Fitbit' ? (
          <FitbitChart data={groupedData} />
        ) : (
          <ActigraphChart data={groupedData} />
        )
      ) : (
        <NoData />
      )}
    </ChartContainer>
  );
};
