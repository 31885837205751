import React from 'react';
import { create } from 'zustand';
import { getGroupsForUser, getAppUsersForGroup } from 'src/queries/dashboard';

const allUserOption = { id: 'All Users', username: 'All Users' };

const defaultSidebarOptions = [
  'wellnessLogs',
  'biometrics',
  // 'fileUpload',
  // 'insights'
];

export const useDashStore = create((set, get) => {
  return {
    loadingNewAppUsers: false,
    sidebarOpen: false,
    setSidebarOpen: (bool) => {
      return set((state) => ({ sidebarOpen: bool }));
    },
    isAdmin: true,

    showAlert: true,
    dismissAlert: () => {
      return set({ showAlert: false });
    },

    /* GROUPS */
    groups: [], // list of groups the user has access to
    setGroups: (newGroups) => {
      return set((state) => ({ groups: newGroups }));
    },
    group: {}, // current selected group
    setGroup: (newGroup) => {
      // set selected group & get/set users for that group
      const { getAppUsers } = get();
      getAppUsers(newGroup.id);
      let newSidebarOptions = [...defaultSidebarOptions];
      if (newGroup.surveys?.includes('nutrition')) {
        newSidebarOptions.push('nutrition');
      }
      if (newGroup.surveys?.includes('exercise')) {
        newSidebarOptions.push('exercise');
      }
      if (newGroup.surveys?.includes('fileUpload')) {
        newSidebarOptions.push('fileUpload');
      }

      set((state) => ({
        group: newGroup,
        loadingNewAppUsers: true,
        sidebarOptions: newSidebarOptions,
      }));
    },
    getGroups: (user) => {
      // get & set initial list of groups
      const { setGroups, setGroup } = get();
      const setOptions = (items) => {
        setGroups(items);
        setGroup(items[0]);
      };
      if (Object.keys(user).length) {
        getGroupsForUser(user, setOptions);
      }
    },

    /* USERS */
    userOptions: [allUserOption],
    selectedUser: allUserOption,
    setSelectedUser: (newUser) => {
      if (newUser.id === 'All Users') {
        set((state) => ({ selectedUser: newUser, selectedAppUserData: {} }));
      } else {
        const { appUsers } = get();
        const userData = appUsers.find((user) => user.id === newUser.id);
        set((state) => ({ selectedUser: newUser, selectedAppUserData: userData }));
      }
    },
    appUsers: [],
    selectedAppUserData: {},
    getAppUsers: (groupID) => {
      const { setUsers } = get();
      getAppUsersForGroup(groupID, setUsers);
    },
    setUsers: (users, groupID) => {
      const options = users
        .map((user) => {
          let parsed = JSON.parse(user.usernames);
          if (typeof parsed === 'string') {
            parsed = JSON.parse(parsed);
          }
          return {
            id: user.id,
            username: parsed[groupID],
          };
        })
        .sort((a, b) => a.username.localeCompare(b.username));
      set({
        userOptions: [allUserOption].concat(options),
        selectedUser: allUserOption,
        appUsers: users,
        loadingNewAppUsers: false,
      });
    },

    /* PAGES */
    sidebarOptions: defaultSidebarOptions,
    activePage: 'wellnessLogs',
    setActivePage: (newPage) => set((state) => ({ activePage: newPage })),
  };
});
